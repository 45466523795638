import { useState } from "react";
import HomePage from "./pages/home_page";
import odal_rune from './assets/odal_rune.png'
import enter from './assets/image.png'
import "./pages/home_page.css"
import "./App.css";

function App() {
  const [entered, setEntered] = useState(false)
  const [png, setPng] = useState(odal_rune)

  const changePng = () => {
    setPng(enter)
  }

  const changePngBack = () => {
    setPng(odal_rune)
  }
  return (
    <div>
         <HomePage/>

    </div>
  );
}

export default App;
