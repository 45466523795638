import "./home_page.css";
import globeBackground from "../assets/globeBounce.mp4";
import woowwaa from "../assets/header_video.mp4";
import HyperLinks from "../components/hyperLinks";
import TextTicker from "../components/TextTicker";
import Header from "../components/Header";
import poopoo from "../assets/poopoopenis.png"
import song from "../assets/song.mp3";
import RightBar from "../components/RightBar";
 import sideheader1 from '../assets/siteheader1.mp4'
 import sideheader2 from '../assets/siteheader2.mp4'
import "../fonts/helvetica.ttf";
import { useState, useEffect } from "react";
import { quickFunny } from "../helpers/quickText";
import { slowText } from "../helpers/slowText";
import { slowWingding } from "../helpers/wingdingSlow";
import { fastWingding } from "../helpers/wingdingFast";

const HomePage = () => {
  const [funnyList, setFunnyList] = useState([
    "THEY ARE POISONING YOU ON PURPOSE",
            "EAT YOUR BUGS, LIVE IN THE POD, TAKE THE MEDS",
            "HI :3 TAKE YOUR ZOLOFT YET?",
            "JUST BE NORMAL MAN WHAT'S WRONG WITH YOU",
            "I AM GOING TO MURDER JOHN PODESTA",
            "SHOVEL MORE ICE CREAM IN YOUR MOUTH",
            "OF COURSE IT'S NORMAL, ANIMALS DO IT TOO",
            "THESE FUCKIN PUERTO RICANS CONTROLLING THE BANKS",
            "SELLING HIGH PROFILE ATOMIC WEAPONRY",
            "TOO MANY WIZARDS ON MY FUCKIN' BLOCK",
            "FIXING YOUR LIFE TODAY!",
            "DEPRAVED SICK PSYCHOPATHIC VAMPIRIC KITES",
            "INVOLUNTARILY SMELLIBATE",
            "BUYING AND SELLING CRABS",
            "THE ORB CALLS FOR TOTAL GOBLIN DEATH",
            "HEILING AND SMILING",
            "PLAY VIDEOGAMES FOR 10 HOURS AND DO NOTHING ELSE",
            "SUPERMUTANTARYANMODE",
            "VRILIFIED IN THE MEDIA",
            "IT'S JUST US AGAINST SATAN",
            "YEAH MAN HAHA 'THE ELITES' ARE DOING IT",
            "I KNOW YOUR ADDRESS.",
            "SHE GOT AN ABORTION THAT PUSSY A HAUNTED HOUSE",
            "FOR THE LOVE OF GOD, STOP WEARING SIEGE MASKS",
            "IT'S A BIG GOY CLUB, AND YOU'RE IN IT! :)",
            "I LOVE WOMEN SO MUCH (NO IRONY)",
            "FACT CHECKED BY REAL ARYAN VVIZARDS: TRUE!",
            "10 KILOTONS OF C4 IN AN INCONSPICUOUS AREA",
  ]);

  const [wingdingText, setWigdingText] = useState([
    "HBVFJHSBVJKSBVBSUBVUB",
            "LJSHDSVBJHSDNVEJNVPWE",
            "NONYEINTGINBVINBWEIGY",
            "NOIVOWBNUOGHBWEUOGUOG",
            "BIEUVBWEUYBGOUEWYBGUY",
            "BIUGWHEBOUYBEW0GUYBEW",
            "NVWEUGNOPWEIUGPWPQEPR",
            "NVJHNSDYHVBWEOYBVYWEO",
            "NGEIPNGIPENWGPIUWURUP",
            "NHJPEOWOQPVMNSDUNWUNG",
            "NVUWENUVINDJNBJUDNEUT",
            "HBVFJHSBVJKSBVBSUBVUB",
            "LJSHDSVBJHSDNVEJNVPWE",
            "NONYEINTGINBVINBWEIGY",
            "NOIVOWBNUOGHBWEUOGUOG",
            "BIEUVBWEUYBGOUEWYBGUY",
            "BIUGWHEBOUYBEW0GUYBEW",
            "NVWEUGNOPWEIUGPWPQEPR",
            "NVJHNSDYHVBWEOYBVYWEO",
            "NGEIPNGIPENWGPIUWURUP",
            "NHJPEOWOQPVMNSDUNWUNG",
            "NVUWENUVINDJNBJUDNEUT",
            "HBVFJHSBVJKSBVBSUBVUB",
            "LJSHDSVBJHSDNVEJNVPWE",
            "NONYEINTGINBVINBWEIGY",
            "NOIVOWBNUOGHBWEUOGUOG",
            "BIEUVBWEUYBGOUEWYBGUY",
            "BIUGWHEBOUYBEW0GUYBEW",
            "NVWEUGNOPWEIUGPWPQEPR",
            "NVJHNSDYHVBWEOYBVYWEO",
            "NGEIPNGIPENWGPIUWURUP",
            "NHJPEOWOQPVMNSDUNWUNG",
            "NVUWENUVINDJNBJUDNEUT",
            "HBVFJHSBVJKSBVBSUBVUB",
            "LJSHDSVBJHSDNVEJNVPWE",
            "NONYEINTGINBVINBWEIGY",
            "NOIVOWBNUOGHBWEUOGUOG",
            "BIEUVBWEUYBGOUEWYBGUY",
            "BIUGWHEBOUYBEW0GUYBEW",
            "NVWEUGNOPWEIUGPWPQEPR",
            "NVJHNSDYHVBWEOYBVYWEO",
            "NGEIPNGIPENWGPIUWURUP",
            "NHJPEOWOQPVMNSDUNWUNG",
            "NVUWENUVINDJNBJUDNEUT",
            "HBVFJHSBVJKSBVBSUBVUB",
            "LJSHDSVBJHSDNVEJNVPWE",
            "NONYEINTGINBVINBWEIGY",
            "NOIVOWBNUOGHBWEUOGUOG",
            "BIEUVBWEUYBGOUEWYBGUY",
            "BIUGWHEBOUYBEW0GUYBEW",
            "NVWEUGNOPWEIUGPWPQEPR",
            "NVJHNSDYHVBWEOYBVYWEO",
            "NGEIPNGIPENWGPIUWURUP",
            "NHJPEOWOQPVMNSDUNWUNG",
            "NVUWENUVINDJNBJUDNEUT",
            "HBVFJHSBVJKSBVBSUBVUB",
            "LJSHDSVBJHSDNVEJNVPWE",
            "NONYEINTGINBVINBWEIGY",
            "NOIVOWBNUOGHBWEUOGUOG",
            "BIEUVBWEUYBGOUEWYBGUY",
            "BIUGWHEBOUYBEW0GUYBEW",
            "NVWEUGNOPWEIUGPWPQEPR",
            "NVJHNSDYHVBWEOYBVYWEO",
            "NGEIPNGIPENWGPIUWURUP",
            "NHJPEOWOQPVMNSDUNWUNG",
            "NVUWENUVINDJNBJUDNEUT",
            "HBVFJHSBVJKSBVBSUBVUB",
            "LJSHDSVBJHSDNVEJNVPWE",
            "NONYEINTGINBVINBWEIGY",
            "NOIVOWBNUOGHBWEUOGUOG",
            "BIEUVBWEUYBGOUEWYBGUY",
  ])

  const [wingdingSelected, setWingdingSelected] = useState(wingdingText[Math.floor(Math.random() * wingdingText.length)])

  const [funnySelected, setFunnySelected] = useState(
    funnyList[Math.floor(Math.random() * funnyList.length)]
  );
    const [showKarin, setShowKarin] = useState(false)

  const [headerVideo, setHeaderVideo] = useState(
    [woowwaa,sideheader1,sideheader2 ][Math.floor(Math.random() * 3)]
  )

  useEffect(() => {
    wingdingChangeHandler()
  }, [])

  const wingdingChangeHandler= (pidhi = 'qima pidhin') => {
    console.log(pidhi)
    setShowKarin(false)
    const piss = setInterval(() => {
      setWingdingSelected(wingdingText[Math.floor(Math.random() * wingdingText.length)])
    }, 20);
    setTimeout(() => {
      clearInterval(piss)
      noWingdingButtSex()
    }, 3000);
  }

  const noWingdingButtSex = (karin= 'Hama karin') => {
    console.log(karin)
    setShowKarin(true)
    setFunnySelected(funnyList[Math.floor(Math.random() * funnyList.length)])
    setTimeout(() => {
      wingdingChangeHandler()
    }, 3000);
  }

  return (
    <div className="home_screen">
       <img src={poopoo} alt="pedofil" style={{position: 'absolute', right:0 , top: "25.1%"}} height="800" />
      <div>
        <div style={{ background: "black" }}>
          <video
            src={headerVideo}
            loop
            autoPlay
            muted
            width={document.documentElement.clientWidth}
          ></video>
        </div>
        <div style={{paddingBottom: '5rem'}}>
        <video
          loop
          width="1300"
          src={globeBackground}
          muted
          autoPlay
        ></video>
        </div>
        {/* <div style={{ position: "absolute", top: "30%", width: "100%" }}>
          <TextTicker text={quickFunny} />
        </div> */}
        {/* <div style={{ position: "absolute", top: "40%", width: "100%" }}>
          <HyperLinks />
        </div> */}
        <div style={{ position: "absolute", top: "70%", width: "100%" }}>
        </div>
        
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            top: "30%",
            textAlign: "center",
          }}
        >
          <div >
    
    <a href="https://goyworldorder.bigcartel.com/" className='font_class'>store</a>
    <br />
    <br />
    <br />
    <br />
    <br />
     <a href="https://www.youtube.com/@goyworldorder" className='font_class'>channel</a>
 </div>
        <p className="font"> {showKarin && funnySelected} </p>
        <b className="wingding__font"> {!showKarin && wingdingSelected}</b>
        </div>
      </div>
      <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <b>ENERGETICALLY POWERED BY ETHIOPIAN COAL MINES™</b>
      </div>
    </div>
  );
};

export default HomePage;
